import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface ConstantsSliceType {
  currentGraduatingYear: number;
  currentIncomingYear: number;

  summerBegins: Dayjs;
  summerEnds: Dayjs;

  yearbookPreOrderDeadline: Dayjs;
  yearbookOrderDeadline: Dayjs;
  yearbookOrderLateDeadline: Dayjs;
  yearbookParentAdDeadline: Dayjs;
  yearbookParentAdLateDeadline: Dayjs;

  registerOrderDeadline: Dayjs;
  registerParentAdDeadline: Dayjs;
  registerParentAdLateDeadline: Dayjs;

  bioformDeadline: Dayjs;
  bioformLateDeadline: Dayjs;
  alternatePhotoDeadline: Dayjs;

  candidsDeadline: Dayjs;
  orgBioformOpen: Dayjs;
  orgBioformDeadline: Dayjs;

  yearbookPreOrderPrice: number;
  yearbookOrderPrice: number;
  registerPreOrderPrice: number;
  registerOrderPrice: number;
  shippingPrice: number;
  internationalShippingPrice: number;
  embossPrice: number;
  yearbookPastEditionsPrice: number;
  registerPastEditionsPrice: number;

  yearbookSilverPrice: number;
  yearbookPlatinumPrice: number;
  yearbookCrimsonPrice: number;
  yearbookJohnHarvardPrice: number;
  registerSilverPrice: number;
  registerPlatinumPrice: number;
  registerCrimsonPrice: number;
  registerJohnHarvardPrice: number;

  cloudinaryCloudName: string;
  cloudinaryUploadPreset: string;
  registerPhotoFolder: string;
  registerParentAdFolder: string;
  yearbookParentAdFolder: string;
  orgPhotoFolder: string;
}

const inspectorInitialState: ConstantsSliceType = {
  currentGraduatingYear: 2025,
  currentIncomingYear: 2028,

  summerBegins: dayjs(new Date('June 1, 2024')),
  summerEnds: dayjs(new Date('September 3, 2024')),

  yearbookPreOrderDeadline: dayjs(new Date('January 31, 2025')),
  yearbookOrderDeadline: dayjs(new Date('March 23, 2025')),
  yearbookOrderLateDeadline: dayjs(new Date('March 23, 2025')),
  yearbookParentAdDeadline: dayjs(new Date('December 15, 2024')),
  yearbookParentAdLateDeadline: dayjs(new Date('December 22, 2024')),

  registerOrderDeadline: dayjs(new Date('September 1, 2024')),
  registerParentAdDeadline: dayjs(new Date('July 15, 2024')),
  registerParentAdLateDeadline: dayjs(new Date('July 15, 2024')),

  bioformDeadline: dayjs(new Date('October 27, 2024')),
  bioformLateDeadline: dayjs(new Date('November 3, 2024')),
  alternatePhotoDeadline: dayjs(new Date('December 15, 2024')),

  candidsDeadline: dayjs(new Date('January 15, 2025')),
  orgBioformOpen: dayjs(new Date('September 3, 2024')),
  orgBioformDeadline: dayjs(new Date('January 5, 2025')),

  yearbookPreOrderPrice: 110,
  yearbookOrderPrice: 140,
  registerPreOrderPrice: 45,
  registerOrderPrice: 50,
  shippingPrice: 10,
  internationalShippingPrice: 35,
  embossPrice: 30,
  yearbookPastEditionsPrice: 140,
  registerPastEditionsPrice: 50,

  yearbookSilverPrice: 300,
  yearbookPlatinumPrice: 500,
  yearbookCrimsonPrice: 800,
  yearbookJohnHarvardPrice: 1600,
  registerSilverPrice: 150,
  registerPlatinumPrice: 250,
  registerCrimsonPrice: 400,
  registerJohnHarvardPrice: 800,

  cloudinaryCloudName: '',
  cloudinaryUploadPreset: '',
  registerPhotoFolder: '',
  registerParentAdFolder: '',
  yearbookParentAdFolder: '',
  orgPhotoFolder: '',
};

const slice = createSlice({
  name: 'constants',
  initialState: inspectorInitialState,
  reducers: {
    setConstants(state, { payload }: PayloadAction<ConstantsSliceType>) {
      state.currentGraduatingYear = payload.currentGraduatingYear;
      state.currentIncomingYear = payload.currentIncomingYear;

      state.summerBegins = payload.summerBegins;
      state.summerEnds = payload.summerEnds;

      state.yearbookPreOrderDeadline = payload.yearbookPreOrderDeadline;
      state.yearbookOrderDeadline = payload.yearbookOrderDeadline;
      state.yearbookOrderLateDeadline = payload.yearbookOrderLateDeadline;
      state.yearbookParentAdDeadline = payload.yearbookParentAdDeadline;
      state.yearbookParentAdLateDeadline = payload.yearbookParentAdLateDeadline;

      state.registerOrderDeadline = payload.registerOrderDeadline;
      state.registerParentAdDeadline = payload.registerParentAdDeadline;
      state.registerParentAdLateDeadline = payload.registerParentAdLateDeadline;

      state.bioformDeadline = payload.bioformDeadline;
      state.bioformLateDeadline = payload.bioformLateDeadline;
      state.alternatePhotoDeadline = payload.alternatePhotoDeadline;

      state.candidsDeadline = payload.candidsDeadline;
      state.orgBioformOpen = payload.orgBioformOpen;
      state.orgBioformDeadline = payload.orgBioformDeadline;

      state.yearbookPreOrderPrice = payload.yearbookPreOrderPrice;
      state.yearbookOrderPrice = payload.yearbookOrderPrice;
      state.registerPreOrderPrice = payload.registerPreOrderPrice;
      state.registerOrderPrice = payload.registerOrderPrice;
      state.shippingPrice = payload.shippingPrice;
      state.internationalShippingPrice = payload.internationalShippingPrice;
      state.embossPrice = payload.embossPrice;
      state.yearbookPastEditionsPrice = payload.yearbookPastEditionsPrice;
      state.registerPastEditionsPrice = payload.registerPastEditionsPrice;

      state.yearbookSilverPrice = payload.yearbookSilverPrice;
      state.yearbookPlatinumPrice = payload.yearbookPlatinumPrice;
      state.yearbookCrimsonPrice = payload.yearbookCrimsonPrice;
      state.yearbookJohnHarvardPrice = payload.yearbookJohnHarvardPrice;
      state.registerSilverPrice = payload.registerSilverPrice;
      state.registerPlatinumPrice = payload.registerPlatinumPrice;
      state.registerCrimsonPrice = payload.registerCrimsonPrice;
      state.registerJohnHarvardPrice = payload.registerJohnHarvardPrice;

      state.cloudinaryCloudName = payload.cloudinaryCloudName;
      state.cloudinaryUploadPreset = payload.cloudinaryUploadPreset;
      state.registerPhotoFolder = payload.registerPhotoFolder;
      state.registerParentAdFolder = payload.registerParentAdFolder;
      state.yearbookParentAdFolder = payload.yearbookParentAdFolder;
      state.orgPhotoFolder = payload.orgPhotoFolder;
    },
  },
});

export const { setConstants } = slice.actions;
export default slice.reducer;
