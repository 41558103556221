import { useDraggable } from '@dnd-kit/core';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { RootStateType, useTypedDispatch } from '@redux/store';
import { setTasks, Task } from '@redux/tasks';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

function Draggable({
  id,
  title,
  checklist,
  dueAt,
  taskStatus,
}: {
  id: number;
  title: string;
  checklist: {
    text: string;
    completed: boolean;
  }[];
  dueAt: Dayjs;
  taskStatus: string;
}) {
  const dispatch = useTypedDispatch();

  const tasks = useSelector((state: RootStateType) => state.tasks.tasks);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<{
    taskName: string;
    taskData: {
      text: string;
      completed: boolean;
    }[];
    taskStatus: string;
    dueAt: Dayjs;
  }>({
    taskName: title,
    taskData: checklist,
    taskStatus,
    dueAt,
  });

  useEffect(() => {
    setEditRow({
      taskName: title,
      taskData: checklist,
      taskStatus,
      dueAt,
    });
  }, [title, checklist, taskStatus, dueAt]);

  const handleClickEvent = (index: number) => {
    const newTasks = tasks.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          taskData: task.taskData.map((taskData, taskDataIndex) => {
            if (taskDataIndex === index) {
              return {
                ...taskData,
                completed: !taskData.completed,
              };
            }

            return taskData;
          }),
        };
      }

      return task;
    });

    const modifiedTask = newTasks.find((task) => task.id === id);

    if (!modifiedTask) return;

    dispatch(
      setTasks({
        tasks: newTasks,
      }),
    );

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        taskStatus: modifiedTask.taskStatus,
        taskData: modifiedTask.taskData,
        dueAt: modifiedTask.dueAt.toISOString(),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== 'success') {
          // eslint-disable-next-line no-alert
          alert('Failed to update task');
        }
      });
  };

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        p: 1,
        paddingBottom: 0,
      }}
      {...attributes}
      {...listeners}
    >
      <Grid container>
        <Grid
          item
          sx={{
            margin: 'auto',
            display: 'flex',
          }}
        >
          <Typography>
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container direction="row-reverse">
            <Grid item>
              <IconButton
                onClick={() => {
                  setOpenEditMenu(true);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      id,
                    }),
                  })
                    .then((res) => res.json())
                    .then((res) => {
                      if (res.status === 'success') {
                        const newTasks = tasks.filter((task) => task.id !== id);

                        dispatch(
                          setTasks({
                            tasks: newTasks,
                          }),
                        );
                      }
                    });
                }}
              >
                <ArchiveIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <List>
        {checklist.map((item, index) => (
          <ListItem key={index} disablePadding dense>
            <ListItemButton
              role={undefined}
              onClick={() => {
                handleClickEvent(index);
              }}
              sx={{
                py: 0,
              }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.completed}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': `checkbox-list-label-${index}`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={`checkbox-list-label-${index}`}
                primary={item.text}
                sx={{
                  textDecoration: item.completed ? 'line-through' : 'none',
                  color: item.completed ? 'gray' : 'black',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Chip
        label={`Due on ${dueAt.format('dddd, MMMM D')}`}
        color="error"
        sx={{
          opacity: 0.7,
          mb: 1,
        }}
      />

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              label="Task Title"
              value={editRow?.taskName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, taskName: e.target.value });
              }}
            />

            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Task Data</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.taskData)
                        setEditRow({
                          ...editRow,
                          taskData: [
                            ...editRow.taskData,
                            { text: '', completed: false },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.taskData && editRow?.taskData.length > 0 && (
                <Grid item xs={12}>
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {editRow?.taskData.map((task, ind) => (
                      <ListItem key={ind}>
                        <ListItemIcon
                          onClick={(_e) =>
                            setEditRow({
                              ...editRow,
                              taskData: editRow?.taskData.filter(
                                (_, i) => i !== ind,
                              ),
                            })
                          }
                        >
                          <ClearIcon
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </ListItemIcon>
                        <TextField
                          fullWidth
                          label={`Task ${ind + 1}`}
                          variant="outlined"
                          value={task.text}
                          onChange={(e) =>
                            setEditRow({
                              ...editRow,
                              taskData: editRow?.taskData.map((val, i) =>
                                i === ind
                                  ? {
                                      text: e.target.value,
                                      completed: false,
                                    }
                                  : val,
                              ),
                            })
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </>

            <DatePicker
              label="Due At"
              value={editRow?.dueAt}
              onChange={(newValue) =>
                setEditRow({
                  ...editRow,
                  dueAt: dayjs(newValue),
                })
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenEditMenu(false)}>
            Cancel
          </Button>
          <Button
            disabled={
              editRow?.taskName === '' || editRow?.taskData.length === 0
            }
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
                  method: 'PUT',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    id,
                    taskStatus: editRow.taskStatus,
                    taskName: editRow.taskName,
                    taskData: editRow.taskData,
                    dueAt: editRow.dueAt.toISOString(),
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/tasks`,
                        {
                          method: 'GET',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => {
                          if (updateRes.status === 'success') {
                            updateRes.data.tasks = updateRes.data.tasks.map(
                              (task: Task) => {
                                task.dueAt = dayjs(task.dueAt);
                                return task;
                              },
                            );

                            dispatch(setTasks({ tasks: updateRes.data.tasks }));
                          }
                        });
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default Draggable;
