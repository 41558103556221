import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { ClearIcon } from '@mui/x-date-pickers';
import { templateNames } from '@pages/ParentAdSubmission';

dayjs.extend(dayOfYear);

interface Row {
  id: string;
  studentFirstName: string;
  studentLastName: string;
  studentEmail: string;
  studentPhone: string;
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentPhone: string;
  paymentStatus: string;
  parentAd: string;
  stripeInvoiceId: string;
  submissionId: string;
  selectedTemplate: number;
  parentAdText: string;
  parentAdPhotos: string[];
  specialRequests: string;
  draftStatus: string;
}

function ParentAds() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 170 },
    {
      field: 'studentFirstName',
      headerName: 'Student First Name',
      minWidth: 170,
    },
    {
      field: 'studentLastName',
      headerName: 'Student Last Name',
      minWidth: 170,
    },
    { field: 'studentEmail', headerName: 'Student Email', minWidth: 170 },
    { field: 'studentPhone', headerName: 'Student Phone', minWidth: 170 },
    {
      field: 'parentFirstName',
      headerName: 'Parent First Name',
      minWidth: 170,
    },
    {
      field: 'parentLastName',
      headerName: 'Parent Last Name',
      minWidth: 170,
    },
    { field: 'parentEmail', headerName: 'Parent Email', minWidth: 170 },
    { field: 'parentPhone', headerName: 'Parent Phone', minWidth: 170 },
    {
      field: 'parentAd',
      headerName: 'Parent Ad Type',
      minWidth: 170,
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      minWidth: 170,
    },
    {
      field: 'stripeInvoiceId',
      headerName: 'Stripe Invoice ID',
      minWidth: 170,
    },
    {
      field: 'submissionId',
      headerName: 'Parent Ad Submission ID',
      minWidth: 170,
    },
    {
      field: 'selectedTemplate',
      headerName: 'Selected Template',
      minWidth: 170,
      valueGetter: (value: number) =>
        typeof value !== 'number'
          ? ''
          : `${templateNames[value - 1]} (${value})`,
    },
    { field: 'parentAdText', headerName: 'Parent Ad Text', minWidth: 170 },
    {
      field: 'parentAdPhotos',
      headerName: 'Parent Ad Photos',
      minWidth: 170,
      valueGetter: (value: string[]) =>
        value.length > 0 ? value.join(', ') : '',
    },
    { field: 'specialRequests', headerName: 'Special Requests', minWidth: 170 },
    { field: 'draftStatus', headerName: 'Draft Status', minWidth: 170 },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Parent Ads</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Parent Ads for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/download_parent_ads${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            ignoreValueFormatterDuringExport
            rows={rows}
            columns={columns}
            getRowId={(row) => row.id}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `parent_ads_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Parent Ads Bioform</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField label="ID" value={editRow?.id} disabled />
            <TextField
              disabled
              label="Student First Name"
              value={editRow?.studentFirstName}
            />
            <TextField
              disabled
              label="Student Last Name"
              value={editRow?.studentLastName}
            />
            <TextField
              disabled
              label="Student Email"
              value={editRow?.studentEmail}
            />
            <TextField
              disabled
              label="Student Phone"
              value={editRow?.studentPhone}
            />
            <TextField
              disabled
              label="Parent First Name"
              value={editRow?.parentFirstName}
            />
            <TextField
              disabled
              label="Parent Last Name"
              value={editRow?.parentLastName}
            />
            <TextField
              disabled
              label="Parent Email"
              value={editRow?.parentEmail}
            />
            <TextField
              disabled
              label="Parent Phone"
              value={editRow?.parentPhone}
            />
            <TextField
              disabled
              label="Payment Status"
              value={editRow?.paymentStatus}
            />
            <TextField
              disabled={typeof editRow?.selectedTemplate !== 'number'}
              label="Selected Template"
              value={editRow?.selectedTemplate}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    selectedTemplate: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              disabled={typeof editRow?.selectedTemplate !== 'number'}
              label="Parent Ad Text"
              value={editRow?.parentAdText}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, parentAdText: e.target.value });
              }}
            />
            {editRow?.parentAdPhotos && editRow?.parentAdPhotos.length > 0 && (
              <Grid item xs={12}>
                <List
                  sx={{
                    padding: 0,
                  }}
                >
                  {editRow?.parentAdPhotos.map((photo, ind) => (
                    <ListItem key={ind}>
                      <ListItemIcon
                        onClick={(_e) =>
                          setEditRow({
                            ...editRow,
                            parentAdPhotos: editRow?.parentAdPhotos.filter(
                              (_, i) => i !== ind,
                            ),
                          })
                        }
                      >
                        <ClearIcon
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                          }}
                        />
                      </ListItemIcon>
                      <TextField
                        fullWidth
                        label={`Photo ${ind + 1}`}
                        variant="outlined"
                        value={photo}
                        onChange={(e) =>
                          setEditRow({
                            ...editRow,
                            parentAdPhotos: editRow?.parentAdPhotos.map(
                              (val, i) => (i === ind ? e.target.value : val),
                            ),
                          })
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            <TextField
              disabled={typeof editRow?.selectedTemplate !== 'number'}
              label="Special Requests"
              value={editRow?.specialRequests}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, specialRequests: e.target.value });
              }}
            />
            <TextField
              disabled={typeof editRow?.selectedTemplate !== 'number'}
              label="Draft Status"
              value={editRow?.draftStatus}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, draftStatus: e.target.value });
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenEditMenu(false)}>
            Cancel
          </Button>
          <Button
            disabled={typeof editRow?.selectedTemplate !== 'number'}
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
                  method: 'DELETE',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    submissionId: editRow.submissionId,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            disabled={typeof editRow?.selectedTemplate !== 'number'}
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`, {
                  method: 'PUT',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    id: editRow.id,
                    selectedTemplate: editRow.selectedTemplate,
                    parentAdText: editRow.parentAdText,
                    parentAdPhotos: editRow.parentAdPhotos,
                    specialRequests: editRow.specialRequests,
                    draftStatus: editRow.draftStatus,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/parent_ads`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ParentAds;
