import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';

interface TaskItem {
  text: string;
  completed: boolean;
}

export interface Task {
  id: number;
  taskName: string;
  taskData: TaskItem[];
  taskStatus: string;
  dueAt: Dayjs;
}

interface TasksSliceType {
  tasks: Task[];
}

const inspectorInitialState: TasksSliceType = {
  tasks: [],
};

const slice = createSlice({
  name: 'constants',
  initialState: inspectorInitialState,
  reducers: {
    setTasks(state, { payload }: PayloadAction<TasksSliceType>) {
      state.tasks = payload.tasks;
    },
  },
});

export const { setTasks } = slice.actions;
export default slice.reducer;
