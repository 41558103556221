import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import constants from './constants';
import tasks from './tasks';

/*
  November 28, 2022:
  There's a reasonable debate on whether or not to allow non-serializable data
  structures in Redux (including Set). Without any grave concerns about doing
  so, we're enabling non-serializable structures and JS Maps and Sets. If anyone
  in the future has a strong argument against allowing non-serializable 
  structures in Redux, they're free to remove the permissions (and re-write the
  prototype functionality that depends on it)
*/

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

/* Separate combined reducer component for a cleaner way to add more slices as
needed. Also simpler to read its type (declared below as RootStateType). */
const reducer = combineReducers({
  constants,
  tasks,
});

enableMapSet();
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

/* Type of state variable (within useSelector(state: RootStateType) =>
state.slice.data) */
export type RootStateType = ReturnType<typeof reducer>;
// Type of dispatch. Ex: const example = (dispatch: DispatchType) => {};
export type DispatchType = typeof store.dispatch;
// useDispatch wrapped with type declarations. Use where useDispatch is used.
export const useTypedDispatch: () => DispatchType = useDispatch;

export default store;

export const persistor = persistStore(store);
