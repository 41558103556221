import { useDroppable } from '@dnd-kit/core';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers';
import { useTypedDispatch } from '@redux/store';
import { setTasks, Task } from '@redux/tasks';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

function Droppable({
  title,
  id,
  color,
  cards,
}: {
  title: string;
  id: string;
  color: 'primary' | 'success' | 'warning';
  cards: React.ReactNode[];
}) {
  const dispatch = useTypedDispatch();
  const theme = useTheme();

  const { setNodeRef } = useDroppable({
    id,
  });

  const [openEditMenu, setOpenEditMenu] = useState(false);
  const [editRow, setEditRow] = useState<{
    taskName: string;
    taskData: {
      text: string;
      completed: boolean;
    }[];
    taskStatus: string;
    dueAt: Dayjs;
  }>({
    taskName: '',
    taskData: [],
    taskStatus: '',
    dueAt: dayjs(),
  });

  return (
    <>
      <Box
        ref={setNodeRef}
        sx={{
          id,
          pt: 1,
          px: 1,
          minHeight: 200,
        }}
      >
        <Grid container>
          <Grid item>
            <Chip label={title} color={color} />
          </Grid>

          <Grid
            container
            spacing={1}
            sx={{
              mt: 1,
              width: '100%',
            }}
          >
            {cards.map((card, index) => (
              <Grid item key={index} xs={12}>
                {card}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>

      {id === 'todo' && (
        <div
          style={{
            marginTop: 8,
            marginLeft: 8,
            marginRight: 16,
            marginBottom: 16,
          }}
        >
          <Button
            fullWidth
            onClick={() => {
              setOpenEditMenu(true);
            }}
            sx={{
              justifyContent: 'left',
              color: theme.palette.grey[600],
            }}
          >
            + New
          </Button>
        </div>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="sm"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              label="Task Title"
              value={editRow?.taskName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, taskName: e.target.value });
              }}
            />

            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Task Data</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.taskData)
                        setEditRow({
                          ...editRow,
                          taskData: [
                            ...editRow.taskData,
                            { text: '', completed: false },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.taskData && editRow?.taskData.length > 0 && (
                <Grid item xs={12}>
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {editRow?.taskData.map((task, ind) => (
                      <ListItem key={ind}>
                        <ListItemIcon
                          onClick={(_e) =>
                            setEditRow({
                              ...editRow,
                              taskData: editRow?.taskData.filter(
                                (_, i) => i !== ind,
                              ),
                            })
                          }
                        >
                          <ClearIcon
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </ListItemIcon>
                        <TextField
                          fullWidth
                          label={`Task ${ind + 1}`}
                          variant="outlined"
                          value={task.text}
                          onChange={(e) =>
                            setEditRow({
                              ...editRow,
                              taskData: editRow?.taskData.map((val, i) =>
                                i === ind
                                  ? {
                                      text: e.target.value,
                                      completed: false,
                                    }
                                  : val,
                              ),
                            })
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </>

            <DatePicker
              label="Due At"
              value={editRow?.dueAt}
              onChange={(newValue) =>
                setEditRow({
                  ...editRow,
                  dueAt: dayjs(newValue),
                })
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenEditMenu(false)}>
            Cancel
          </Button>
          <Button
            disabled={
              editRow?.taskName === '' || editRow?.taskData.length === 0
            }
            onClick={() => {
              if (editRow) {
                fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    taskName: editRow.taskName,
                    taskData: editRow.taskData,
                    dueAt: editRow.dueAt.toISOString(),
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/tasks`,
                        {
                          method: 'GET',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => {
                          if (updateRes.status === 'success') {
                            updateRes.data.tasks = updateRes.data.tasks.map(
                              (task: Task) => {
                                task.dueAt = dayjs(task.dueAt);
                                return task;
                              },
                            );

                            dispatch(setTasks({ tasks: updateRes.data.tasks }));
                          }
                        });
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Droppable;
