import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CloudinaryUploadWidget from '@components/CloudinaryUploadWidget';
import Footer from '@components/Footer';
import Header from '@components/Header';
import { houseList } from '@constants/houseList';

function Candids() {
  const [houseName, setHouseName] = useState<string | null>(null);
  const [studentName, setStudentName] = useState<string>('');

  return (
    <>
      <Header />

      <Box
        sx={{
          width: '100%',
          pt: 4,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Senior Candids Submission</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              The deadline for submitting senior candids is January 15th, 2025
              11:59 PM. If you have questions regarding senior candid
              submissions, please contact us at{' '}
              <a href="mailto:inquiries@harvardyearbook.com">
                inquiries@harvardyearbook.com
              </a>
              . We will get back to you as soon as possible!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Each photo MUST be at least 500KB in size or it will NOT appear in
              the book. Please also note that the photos you submit below will
              only be displayed in your House&apos;s section of the Yearbook. We
              only collect names in case we need to contact you about your
              submission. Candids included in the yearbook will not include your
              name. Please make your best judgement on what is appropriate to
              submit, through our yearbook staff reserve the right to choose
              what to include.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="student-name"
              label="Student Name"
              variant="outlined"
              value={studentName}
              onChange={(e) => setStudentName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              value={houseName}
              getOptionDisabled={(option) => option === houseName}
              onChange={(_e, newValue) => setHouseName(newValue)}
              options={houseList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="house"
                  label="House"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <CloudinaryUploadWidget
              uwConfig={{
                cloudName: 'harvard-yearbook',
                uploadPreset: 'b266p2uw',
                folder: `candids_389`,
              }}
              fileName={
                studentName !== ''
                  ? `${houseName}/${studentName}/${studentName}`
                  : ''
              }
              openWidget={studentName !== '' && houseName !== null}
              setPhotoLink={() => {}}
              allowMulti
            />
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </>
  );
}

export default Candids;
