import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cloudinary: any;
  }
}

function CloudinaryUploadWidget({
  uwConfig,
  fileName,
  openWidget,
  setPhotoLink,
  index,
  allowMulti,
}: {
  uwConfig: {
    cloudName: string;
    uploadPreset: string;
    folder: string;
  };
  fileName: string;
  openWidget: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPhotoLink: (link: string, index?: number) => void;
  index?: number;
  allowMulti?: boolean;
}) {
  const [photoUploaded, setPhotoUploaded] = useState<boolean>(false);
  const [widgetCreated, setWidgetCreated] = useState<boolean>(false);

  const myWidget = useMemo(() => {
    if (window.cloudinary && !widgetCreated) {
      setWidgetCreated(true);

      return window.cloudinary.createUploadWidget(
        {
          ...uwConfig,
          publicId: `${fileName}_${dayjs
            .utc()
            .tz('America/New_York')
            .valueOf()
            .toString()}`,
          multiple: allowMulti,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any, result: any) => {
          if (!error && result && result.event === 'success') {
            setPhotoLink(result.info.url, index);
            setPhotoUploaded(true);
          }
        },
      );
    }

    return null;
  }, [window.cloudinary]);

  useEffect(() => {
    if (myWidget) {
      myWidget.update({
        publicId: `${fileName}_${dayjs
          .utc()
          .tz('America/New_York')
          .valueOf()
          .toString()}`,
      });
    }
  }, [fileName]);

  return (
    <Button
      variant="contained"
      id="upload_widget"
      disabled={photoUploaded || !openWidget}
      onClick={() => {
        if (openWidget) myWidget.open();
      }}
    >
      {photoUploaded ? 'Photo Uploaded' : 'Upload'}
    </Button>
  );
}

export default CloudinaryUploadWidget;
