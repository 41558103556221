/* eslint-disable react/jsx-no-bind */
import Draggable from '@components/dashboard/Draggable';
import Droppable from '@components/dashboard/Droppable';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RootStateType, useTypedDispatch } from '@redux/store';
import { setTasks, Task } from '@redux/tasks';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

interface ListType {
  id: string;
  statusType: string;
  title: string;
  color: 'primary' | 'success' | 'warning';
}

const lists: ListType[] = [
  {
    id: 'todo',
    statusType: 'todo',
    title: 'Todo',
    color: 'primary',
  },
  {
    id: 'inProgress',
    statusType: 'inProgress',
    title: 'In Progress',
    color: 'warning',
  },
  {
    id: 'done',
    statusType: 'done',
    title: 'Done',
    color: 'success',
  },
];

function DashboardPage() {
  const theme = useTheme();
  const dispatch = useTypedDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const tasks = useSelector((state: RootStateType) => state.tasks.tasks);

  const [revenue, setRevenue] = useState<number | null>();
  const [booksSold, setBooksSold] = useState<number | null>();
  const [embossed, setEmbossed] = useState<number | null>();
  const [parentAds, setParentAds] = useState<number | null>();
  const [activeId, setActiveId] = useState<number | null>();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/stats`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setRevenue(res.data.orderTotal);
          setBooksSold(res.data.yearbookOrderCount);
          setEmbossed(res.data.embossedCount);
          setParentAds(res.data.parentAds);
        }
      });
  }, []);

  const intlNumberFormat = useMemo(() => new Intl.NumberFormat('en-US'), []);
  const intlCurrencyFormat = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    [],
  );

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          res.data.tasks = res.data.tasks.map((task: Task) => {
            task.dueAt = dayjs(task.dueAt);
            return task;
          });

          dispatch(setTasks({ tasks: res.data.tasks }));
        }
      });
  }, []);

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    const id = active.id as string;
    const overId = over?.id as string;

    const activeTask = tasks.find((task) => task.id === activeId);

    if (!id || !overId || !activeTask || activeTask.taskStatus === overId) {
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/tasks`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: activeId,
        taskStatus: event.over?.id,
        taskData: activeTask?.taskData,
        dueAt: activeTask?.dueAt.toISOString(),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status !== 'success') {
          // eslint-disable-next-line no-alert
          alert('Failed to update task');
        }
      });
  }

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event;
    const id = active.id as string;
    const overId = over?.id as string;

    const activeTask = tasks.find((task) => task.id === activeId);

    if (!id || !overId || !activeTask) {
      return;
    }

    dispatch(
      setTasks({
        tasks: [
          ...tasks.filter((task) => task.id !== activeId),
          {
            ...activeTask,
            taskStatus: overId,
          },
        ].sort((a, b) => a.id - b.id),
      }),
    );
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    const id = active.id as number;

    setActiveId(id);
  }

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Staff</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={4}
          display="flex"
          justifyContent="center"
        >
          <Paper
            sx={{
              padding: 2,
            }}
          >
            <Typography variant="h4">Revenue</Typography>
            <Typography variant="body1">
              {typeof revenue === 'number'
                ? intlCurrencyFormat.format(revenue)
                : '$xxx,xxx'}
            </Typography>
          </Paper>

          <Paper
            sx={{
              padding: 2,
            }}
          >
            <Typography variant="h4">Books Sold</Typography>
            <Typography variant="body1">
              {typeof booksSold === 'number'
                ? intlNumberFormat.format(booksSold)
                : 'x,xxx'}{' '}
              books
            </Typography>
          </Paper>

          <Paper
            sx={{
              padding: 2,
            }}
          >
            <Typography variant="h4">Embossed</Typography>
            <Typography variant="body1">
              {typeof embossed === 'number'
                ? intlNumberFormat.format(embossed)
                : 'xxx'}{' '}
              books
            </Typography>
          </Paper>

          <Paper
            sx={{
              padding: 2,
            }}
          >
            <Typography variant="h4">Parent Ads</Typography>
            <Typography variant="body1">
              {typeof parentAds === 'number'
                ? intlNumberFormat.format(parentAds)
                : 'xxx'}{' '}
              ads
            </Typography>
          </Paper>
        </Stack>

        <Paper
          elevation={3}
          sx={{
            mt: 4,
            pt: 2,
            borderRadius: 1,
            backgroundColor: theme.palette.grey[200],
            minHeight: '500px',
          }}
        >
          <Typography variant="h4" display="flex" justifyContent="center">
            Task List
          </Typography>

          <Grid
            container
            spacing={2}
            sx={{
              px: 2,
              pt: 2,
            }}
          >
            {lists.map((list) => (
              <Grid item xs={4}>
                <Droppable
                  key={list.id}
                  title={list.title}
                  color={list.color}
                  id={list.id}
                  cards={tasks
                    .filter((task) => task.taskStatus === list.statusType)
                    .map((task) => [
                      <Draggable
                        key={task.id}
                        id={task.id}
                        title={task.taskName}
                        checklist={task.taskData}
                        taskStatus={task.taskStatus}
                        dueAt={task.dueAt}
                      />,
                    ])}
                />
              </Grid>
            ))}
            <DragOverlay>
              {activeId ? (
                <Paper
                  sx={{
                    p: 1,
                    paddingBottom: 0,
                    id: activeId,
                  }}
                >
                  <Typography>
                    <b>
                      {tasks.find((task) => task.id === activeId)?.taskName}
                    </b>
                  </Typography>

                  <List>
                    {tasks
                      .find((task) => task.id === activeId)
                      ?.taskData.map((item, index) => (
                        <ListItem key={index} disablePadding dense>
                          <ListItemButton
                            role={undefined}
                            sx={{
                              py: 0,
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={item.completed}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  'aria-labelledby': `checkbox-list-label-${index}`,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={`checkbox-list-label-${index}`}
                              primary={item.text}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                  <Chip
                    label={`Due on ${tasks.find((task) => task.id === activeId)?.dueAt.format('dddd, MMMM D')}`}
                    color="error"
                    sx={{
                      opacity: 0.7,
                      mb: 1,
                    }}
                  />
                </Paper>
              ) : null}
            </DragOverlay>
          </Grid>
        </Paper>
      </Box>
    </DndContext>
  );
}

export default DashboardPage;
