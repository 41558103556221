import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CloudinaryUploadWidget from '@components/CloudinaryUploadWidget';
import Footer from '@components/Footer';
import Header from '@components/Header';

export const templateNames: string[] = [
  'Silver #1',
  'Silver #2',
  'Silver #3',
  'Silver #4',
  'Silver #5',
  'Silver #6',
  'Platinum #1',
  'Platinum #2',
  'Platinum #3',
  'Platinum #4',
  'Platinum #5',
  'Platinum #6',
  'Platinum #7',
  'Crimson #1',
  'Crimson #2',
  'Crimson #3',
  'Crimson #4',
  'Crimson #5',
  'Crimson #6',
  'Crimson #7',
  'Crimson #8',
  'Crimson #9',
  'John Harvard #1',
  'John Harvard #2',
  'John Harvard #3',
];

const imagesByTemplates: number[] = [
  1, 1, 1, 1, 1, 2, 1, 2, 1, 1, 2, 4, 4, 1, 2, 8, 8, 2, 10, 10, 4, 4, 10, 6, 1,
];

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
  );
}

function ParentAdSubmission() {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  const [selectedTemplate, setSelectedTemplate] = useState<number | null>(null);
  const [content, setContent] = useState<string>('');
  const [specialRequests, setSpecialRequests] = useState<string>('');

  const [submittedPhotos, setSubmittedPhotos] = useState<string[]>([]);
  const [newPhoto, setNewPhoto] = useState<{
    url: string;
    index: number;
  } | null>(null);

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const parentAdId = searchParams.get('id');
  const [studentName, setStudentName] = useState<string>('');

  const setPhotoLink = (photoLink: string, index?: number) => {
    if (index !== undefined) {
      setNewPhoto({ url: photoLink, index });
    }
  };

  useEffect(() => {
    if (newPhoto) {
      setSubmittedPhotos(
        submittedPhotos.map((photo, ind) =>
          ind === newPhoto.index ? newPhoto.url : photo,
        ),
      );
      setNewPhoto(null);
    }
  }, [newPhoto]);

  useEffect(() => {
    if (parentAdId && parentAdId !== '') {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/parent_ads/${parentAdId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'success') {
            setStudentName(data.data.studentName);
          } else {
            // eslint-disable-next-line no-alert
            alert('There was an error fetching your information');
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert('There was an error fetching your information');
        });
    }
  }, [parentAdId]);

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      selectedTemplate: selectedTemplate !== null,
      submittedPhotos: submittedPhotos.length > 0,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/parent_ads`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        parentAdId,
        selectedTemplate,
        submittedPhotos,
        content,
        specialRequests,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          if (data.data.url && data.data.url !== '') {
            window.location = data.data.url;
          } else {
            navigate('/success_parent_ads', { replace: true });
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your information');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting your information. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Header />

      <Box sx={{ display: 'flex', minHeight: 'calc(100vh - 176px)' }}>
        <Box
          sx={{
            pl: 4,
            width: '500px',
            pt: 4,
          }}
        >
          {studentName && studentName !== '' && (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h4">
                  Parent Ad Submission for {toTitleCase(studentName)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                  }}
                >
                  Select Template:
                </Typography>

                <Select
                  required
                  displayEmpty
                  value={selectedTemplate}
                  onChange={(e) => {
                    if (e.target.value === null) {
                      return setSelectedTemplate(null);
                    }

                    const newTemplate = parseInt(
                      JSON.stringify(e.target.value),
                      10,
                    );

                    if (
                      selectedTemplate &&
                      imagesByTemplates[newTemplate - 1] !==
                        imagesByTemplates[selectedTemplate - 1]
                    ) {
                      const oldNumber = imagesByTemplates[selectedTemplate - 1];
                      const newNumber = imagesByTemplates[newTemplate - 1];

                      if (oldNumber > newNumber) {
                        setSubmittedPhotos(submittedPhotos.slice(0, newNumber));
                      } else {
                        setSubmittedPhotos([
                          ...submittedPhotos,
                          ...new Array(newNumber - oldNumber).fill(
                            '',
                            0,
                            newNumber - oldNumber,
                          ),
                        ]);
                      }
                    } else {
                      setSubmittedPhotos(
                        new Array(imagesByTemplates[newTemplate - 1]).fill(''),
                      );
                    }

                    return setSelectedTemplate(newTemplate);
                  }}
                >
                  {templateNames.map((name, ind) => (
                    <MenuItem value={ind + 1} key={ind + 1}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    pr: 1,
                  }}
                >
                  View all templates{' '}
                  <a
                    href="https://static1.squarespace.com/static/6480fe1b85305143e810e5e8/t/66f2d681932cc1079127bd1b/1727190661650/HYP+389+Parent+Ads.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </Typography>
              </Grid>

              {selectedTemplate &&
                Array.from(
                  { length: imagesByTemplates[selectedTemplate - 1] },
                  (_, i) => i,
                ).map((num) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          pr: 1,
                        }}
                      >
                        Insert Image {JSON.stringify(num + 1)}:
                      </Typography>
                      <CloudinaryUploadWidget
                        uwConfig={{
                          cloudName: 'harvard-yearbook',
                          uploadPreset: 'b266p2uw',
                          folder: `parent_ads_389/${studentName}`,
                        }}
                        fileName={`${studentName}_${num + 1}`}
                        openWidget={studentName !== ''}
                        setPhotoLink={setPhotoLink}
                        index={num}
                      />
                    </Grid>
                    {submittedPhotos[num] && submittedPhotos[num] !== '' && (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>
                          Image {num + 1} Link:{' '}
                          <a
                            href={submittedPhotos[num]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            click here
                          </a>
                        </Typography>
                      </Grid>
                    )}
                  </>
                ))}

              <Grid item xs={12}>
                <Typography>Content:</Typography>
                <TextField
                  fullWidth
                  multiline
                  label="Content"
                  rows={4}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>Special Requests:</Typography>
                <TextField
                  fullWidth
                  label="Special Requests"
                  value={specialRequests}
                  onChange={(e) => setSpecialRequests(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Button fullWidth variant="contained" onClick={submitBioform}>
                  {submittingInfo ? <CircularProgress /> : 'Submit'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      <Footer />
    </>
  );
}

export default ParentAdSubmission;
