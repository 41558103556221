import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store, { persistor } from '@redux/store';
import * as Sentry from '@sentry/react';
import App from './App';

Sentry.init({
  dsn: 'https://4ceaa4a023aa2e0a9f13bbbdd0273ec2@o4508464452468736.ingest.us.sentry.io/4508464456925184',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed
  // tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  // This sets the sample rate at 10%. You may
  // want to change it to 100% while in development and then sample at a lower
  // rate in production.
  replaysOnErrorSampleRate: 1.0,
  // If you're not already sampling the entire
  // session, change the sample rate to 100% when sampling sessions where errors
  // occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
);
