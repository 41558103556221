import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import Header from '@components/Header';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookIcon from '@mui/icons-material/Book';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import ConstantsPage from '@pages/staff/ConstantsPage';
import FreshmanRegisterOrders from '@pages/staff/FreshmanRegisterOrders';
import SalesReport from '@pages/staff/SalesReport';
import YearbookOrders from '@pages/staff/YearbookOrders';
import { useNavigate } from 'react-router-dom';
import DashboardPage from './staff/Dashboard';
import YearbookBioforms from './staff/YearbookBioforms';
import OrganizationBioforms from './staff/OrganizationBioforms';
import ParentAds from './staff/ParentAds';

type ListItemType = {
  text: string;
  icon: React.ReactNode;
  page:
    | 'dashboard'
    | 'yearbook_orders'
    | 'freshman_register_orders'
    | 'yearbook_bioforms'
    | 'organization_bioforms'
    | 'parent_ads'
    | 'sales'
    | 'constants';
  pageComponent: React.ReactNode;
};

const mainListItems: ListItemType[] = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    page: 'dashboard',
    pageComponent: <DashboardPage />,
  },
  {
    text: 'Yearbook Orders',
    icon: <PhotoAlbumIcon />,
    page: 'yearbook_orders',
    pageComponent: <YearbookOrders />,
  },
  {
    text: 'Register Orders',
    icon: <BookIcon />,
    page: 'freshman_register_orders',
    pageComponent: <FreshmanRegisterOrders />,
  },
  {
    text: 'Yearbook Bioforms',
    icon: <CallToActionIcon />,
    page: 'yearbook_bioforms',
    pageComponent: <YearbookBioforms />,
  },
  {
    text: 'Organization Bioforms',
    icon: <BookIcon />,
    page: 'organization_bioforms',
    pageComponent: <OrganizationBioforms />,
  },
  {
    text: 'Parent Ads',
    icon: <BookIcon />,
    page: 'parent_ads',
    pageComponent: <ParentAds />,
  },
  {
    text: 'Sales **',
    icon: <AttachMoneyIcon />,
    page: 'sales',
    pageComponent: <SalesReport />,
  },
  {
    text: 'Constants',
    icon: <SettingsIcon />,
    page: 'constants',
    pageComponent: <ConstantsPage />,
  },
];

function Staff({ page }: { page: string }) {
  const navigate = useNavigate();

  return (
    <>
      <Header />

      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Box
            top="100px"
            height="calc(100% - 100px)"
            width="20%"
            display="grid"
          >
            <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
              <List dense>
                {mainListItems.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      selected={item.page === page}
                      onClick={() => {
                        navigate(`/staff/${item.page}`, { replace: true });
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Box
            sx={{
              pl: '10%',
              width: '80%',
              pt: 4,
              display: 'grid',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '200px',
                width: 'calc(100% - 200px)',
                pb: 4,
              }}
            >
              {mainListItems
                .filter((item) => item.page === page)
                .map((item) => item.pageComponent)}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Staff;
