import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { setConstants } from '@redux/constants';
import { RootStateType, useTypedDispatch } from '@redux/store';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Input {
  name: string;
  id: string;
}

type TypedInput = Input &
  (
    | {
        type: 'number';
        value: number;
        setValue: (value: number) => void;
      }
    | {
        type: 'text';
        value: string;
        setValue: (value: string) => void;
      }
    | {
        type: 'date';
        value: Dayjs;
        setValue: (value: Dayjs) => void;
      }
  );

interface Category {
  name: string;
  inputs: TypedInput[];
}

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

function ConstantsPage() {
  const dispatch = useTypedDispatch();

  const currentGraduatingYear = useSelector(
    (state: RootStateType) => state.constants.currentGraduatingYear,
  );
  const currentIncomingYear = useSelector(
    (state: RootStateType) => state.constants.currentIncomingYear,
  );
  const summerBegins = useSelector(
    (state: RootStateType) => state.constants.summerBegins,
  );
  const summerEnds = useSelector(
    (state: RootStateType) => state.constants.summerEnds,
  );
  const yearbookPreOrderDeadline = useSelector(
    (state: RootStateType) => state.constants.yearbookPreOrderDeadline,
  );
  const yearbookOrderDeadline = useSelector(
    (state: RootStateType) => state.constants.yearbookOrderDeadline,
  );
  const yearbookOrderLateDeadline = useSelector(
    (state: RootStateType) => state.constants.yearbookOrderLateDeadline,
  );
  const yearbookParentAdDeadline = useSelector(
    (state: RootStateType) => state.constants.yearbookParentAdDeadline,
  );
  const yearbookParentAdLateDeadline = useSelector(
    (state: RootStateType) => state.constants.yearbookParentAdLateDeadline,
  );
  const registerOrderDeadline = useSelector(
    (state: RootStateType) => state.constants.registerOrderDeadline,
  );
  const registerParentAdDeadline = useSelector(
    (state: RootStateType) => state.constants.registerParentAdDeadline,
  );
  const registerParentAdLateDeadline = useSelector(
    (state: RootStateType) => state.constants.registerParentAdLateDeadline,
  );
  const bioformDeadline = useSelector(
    (state: RootStateType) => state.constants.bioformDeadline,
  );
  const bioformLateDeadline = useSelector(
    (state: RootStateType) => state.constants.bioformLateDeadline,
  );
  const alternatePhotoDeadline = useSelector(
    (state: RootStateType) => state.constants.alternatePhotoDeadline,
  );
  const candidsDeadline = useSelector(
    (state: RootStateType) => state.constants.candidsDeadline,
  );
  const orgBioformOpen = useSelector(
    (state: RootStateType) => state.constants.orgBioformOpen,
  );
  const orgBioformDeadline = useSelector(
    (state: RootStateType) => state.constants.orgBioformDeadline,
  );
  const yearbookPreOrderPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookPreOrderPrice,
  );
  const yearbookOrderPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookOrderPrice,
  );
  const registerPreOrderPrice = useSelector(
    (state: RootStateType) => state.constants.registerPreOrderPrice,
  );
  const registerOrderPrice = useSelector(
    (state: RootStateType) => state.constants.registerOrderPrice,
  );
  const shippingPrice = useSelector(
    (state: RootStateType) => state.constants.shippingPrice,
  );
  const internationalShippingPrice = useSelector(
    (state: RootStateType) => state.constants.internationalShippingPrice,
  );
  const embossPrice = useSelector(
    (state: RootStateType) => state.constants.embossPrice,
  );
  const yearbookPastEditionsPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookPastEditionsPrice,
  );
  const registerPastEditionsPrice = useSelector(
    (state: RootStateType) => state.constants.registerPastEditionsPrice,
  );
  const yearbookSilverPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookSilverPrice,
  );
  const yearbookPlatinumPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookPlatinumPrice,
  );
  const yearbookJohnHarvardPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookJohnHarvardPrice,
  );
  const yearbookCrimsonPrice = useSelector(
    (state: RootStateType) => state.constants.yearbookCrimsonPrice,
  );
  const registerSilverPrice = useSelector(
    (state: RootStateType) => state.constants.registerSilverPrice,
  );
  const registerPlatinumPrice = useSelector(
    (state: RootStateType) => state.constants.registerPlatinumPrice,
  );
  const registerCrimsonPrice = useSelector(
    (state: RootStateType) => state.constants.registerCrimsonPrice,
  );
  const registerJohnHarvardPrice = useSelector(
    (state: RootStateType) => state.constants.registerJohnHarvardPrice,
  );
  const cloudinaryCloudName = useSelector(
    (state: RootStateType) => state.constants.cloudinaryCloudName,
  );
  const cloudinaryUploadPreset = useSelector(
    (state: RootStateType) => state.constants.cloudinaryUploadPreset,
  );
  const registerPhotoFolder = useSelector(
    (state: RootStateType) => state.constants.registerPhotoFolder,
  );
  const registerParentAdFolder = useSelector(
    (state: RootStateType) => state.constants.registerParentAdFolder,
  );
  const yearbookParentAdFolder = useSelector(
    (state: RootStateType) => state.constants.yearbookParentAdFolder,
  );
  const orgPhotoFolder = useSelector(
    (state: RootStateType) => state.constants.orgPhotoFolder,
  );

  const [editState, setEditState] = useState({
    currentGraduatingYear,
    currentIncomingYear,
    summerBegins,
    summerEnds,
    yearbookPreOrderDeadline,
    yearbookOrderDeadline,
    yearbookOrderLateDeadline,
    yearbookParentAdDeadline,
    yearbookParentAdLateDeadline,
    registerOrderDeadline,
    registerParentAdDeadline,
    registerParentAdLateDeadline,
    bioformDeadline,
    bioformLateDeadline,
    alternatePhotoDeadline,
    candidsDeadline,
    orgBioformOpen,
    orgBioformDeadline,
    yearbookPreOrderPrice,
    yearbookOrderPrice,
    registerPreOrderPrice,
    registerOrderPrice,
    shippingPrice,
    internationalShippingPrice,
    embossPrice,
    yearbookPastEditionsPrice,
    registerPastEditionsPrice,
    yearbookSilverPrice,
    yearbookPlatinumPrice,
    yearbookJohnHarvardPrice,
    yearbookCrimsonPrice,
    registerSilverPrice,
    registerPlatinumPrice,
    registerCrimsonPrice,
    registerJohnHarvardPrice,
    cloudinaryCloudName,
    cloudinaryUploadPreset,
    registerPhotoFolder,
    registerParentAdFolder,
    yearbookParentAdFolder,
    orgPhotoFolder,
  });

  useEffect(() => {
    setEditState({
      currentGraduatingYear,
      currentIncomingYear,
      summerBegins,
      summerEnds,
      yearbookPreOrderDeadline,
      yearbookOrderDeadline,
      yearbookOrderLateDeadline,
      yearbookParentAdDeadline,
      yearbookParentAdLateDeadline,
      registerOrderDeadline,
      registerParentAdDeadline,
      registerParentAdLateDeadline,
      bioformDeadline,
      bioformLateDeadline,
      alternatePhotoDeadline,
      candidsDeadline,
      orgBioformOpen,
      orgBioformDeadline,
      yearbookPreOrderPrice,
      yearbookOrderPrice,
      registerPreOrderPrice,
      registerOrderPrice,
      shippingPrice,
      internationalShippingPrice,
      embossPrice,
      yearbookPastEditionsPrice,
      registerPastEditionsPrice,
      yearbookSilverPrice,
      yearbookPlatinumPrice,
      yearbookJohnHarvardPrice,
      yearbookCrimsonPrice,
      registerSilverPrice,
      registerPlatinumPrice,
      registerCrimsonPrice,
      registerJohnHarvardPrice,
      cloudinaryCloudName,
      cloudinaryUploadPreset,
      registerPhotoFolder,
      registerParentAdFolder,
      yearbookParentAdFolder,
      orgPhotoFolder,
    });
  }, [
    currentGraduatingYear,
    currentIncomingYear,
    summerBegins,
    summerEnds,
    yearbookPreOrderDeadline,
    yearbookOrderDeadline,
    yearbookOrderLateDeadline,
    yearbookParentAdDeadline,
    yearbookParentAdLateDeadline,
    registerOrderDeadline,
    registerParentAdDeadline,
    registerParentAdLateDeadline,
    bioformDeadline,
    bioformLateDeadline,
    alternatePhotoDeadline,
    candidsDeadline,
    orgBioformOpen,
    orgBioformDeadline,
    yearbookPreOrderPrice,
    yearbookOrderPrice,
    registerPreOrderPrice,
    registerOrderPrice,
    shippingPrice,
    internationalShippingPrice,
    embossPrice,
    yearbookPastEditionsPrice,
    registerPastEditionsPrice,
    yearbookSilverPrice,
    yearbookPlatinumPrice,
    yearbookJohnHarvardPrice,
    yearbookCrimsonPrice,
    registerSilverPrice,
    registerPlatinumPrice,
    registerCrimsonPrice,
    registerJohnHarvardPrice,
    cloudinaryCloudName,
    cloudinaryUploadPreset,
    registerPhotoFolder,
    registerParentAdFolder,
    yearbookParentAdFolder,
    orgPhotoFolder,
  ]);

  const categories: Category[] = [
    {
      name: 'Years',
      inputs: [
        {
          name: 'Current Graduating Year',
          id: 'currentGraduatingYear',
          type: 'number',
          value: editState.currentGraduatingYear,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              currentGraduatingYear: value,
            });
          },
        },
        {
          name: 'Current Incoming Year',
          id: 'currentIncomingYear',
          type: 'number',
          value: editState.currentIncomingYear,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              currentIncomingYear: value,
            });
          },
        },
      ],
    },
    {
      name: 'Summer Dates',
      inputs: [
        {
          name: 'Summer Begins',
          id: 'summerBegins',
          type: 'date',
          value: editState.summerBegins,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              summerBegins: value,
            });
          },
        },
        {
          name: 'Summer Ends',
          id: 'summerEnds',
          type: 'date',
          value: editState.summerEnds,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              summerEnds: value,
            });
          },
        },
      ],
    },
    {
      name: 'Yearbook Deadlines',
      inputs: [
        {
          name: 'Pre-Order Deadline',
          id: 'yearbookPreOrderDeadline',
          type: 'date',
          value: editState.yearbookPreOrderDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              yearbookPreOrderDeadline: value,
            });
          },
        },
        {
          name: 'Order Deadline',
          id: 'yearbookOrderDeadline',
          type: 'date',
          value: editState.yearbookOrderDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              yearbookOrderDeadline: value,
            });
          },
        },
        {
          name: 'Order Late Deadline',
          id: 'yearbookOrderLateDeadline',
          type: 'date',
          value: editState.yearbookOrderLateDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              yearbookOrderLateDeadline: value,
            });
          },
        },
        {
          name: 'Parent Ad Deadline',
          id: 'yearbookParentAdDeadline',
          type: 'date',
          value: editState.yearbookParentAdDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              yearbookParentAdDeadline: value,
            });
          },
        },
        {
          name: 'Parent Ad Late Deadline',
          id: 'yearbookParentAdLateDeadline',
          type: 'date',
          value: editState.yearbookParentAdLateDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              yearbookParentAdLateDeadline: value,
            });
          },
        },
      ],
    },
    {
      name: 'Register Deadlines',
      inputs: [
        {
          name: 'Order Deadline',
          id: 'registerOrderDeadline',
          type: 'date',
          value: editState.registerOrderDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              registerOrderDeadline: value,
            });
          },
        },
        {
          name: 'Parent Ad Deadline',
          id: 'registerParentAdDeadline',
          type: 'date',
          value: editState.registerParentAdDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              registerParentAdDeadline: value,
            });
          },
        },
        {
          name: 'Parent Ad Late Deadline',
          id: 'registerParentAdLateDeadline',
          type: 'date',
          value: editState.registerParentAdLateDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              registerParentAdLateDeadline: value,
            });
          },
        },
      ],
    },
    {
      name: 'Bioform, Candid, Photo Deadlines',
      inputs: [
        {
          name: 'Bioform Deadline',
          id: 'bioformDeadline',
          type: 'date',
          value: editState.bioformDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              bioformDeadline: value,
            });
          },
        },
        {
          name: 'Bioform Late Deadline',
          id: 'bioformLateDeadline',
          type: 'date',
          value: editState.bioformLateDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              bioformLateDeadline: value,
            });
          },
        },
        {
          name: 'Alternate Photo Deadline',
          id: 'alternatePhotoDeadline',
          type: 'date',
          value: editState.alternatePhotoDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              alternatePhotoDeadline: value,
            });
          },
        },
        {
          name: 'Candids Deadline',
          id: 'candidsDeadline',
          type: 'date',
          value: editState.candidsDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              candidsDeadline: value,
            });
          },
        },
        {
          name: 'Org Bioform Open',
          id: 'orgBioformOpen',
          type: 'date',
          value: editState.orgBioformOpen,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              orgBioformOpen: value,
            });
          },
        },
        {
          name: 'Org Bioform Deadline',
          id: 'orgBioformDeadline',
          type: 'date',
          value: editState.orgBioformDeadline,
          setValue: (value: Dayjs) => {
            setEditState({
              ...editState,
              orgBioformDeadline: value,
            });
          },
        },
      ],
    },
    {
      name: 'Pricing',
      inputs: [
        {
          name: 'Yearbook Pre-Order Price',
          id: 'yearbookPreOrderPrice',
          type: 'number',
          value: editState.yearbookPreOrderPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookPreOrderPrice: value,
            });
          },
        },
        {
          name: 'Yearbook Order Price',
          id: 'yearbookOrderPrice',
          type: 'number',
          value: editState.yearbookOrderPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookOrderPrice: value,
            });
          },
        },
        {
          name: 'Register Pre-Order Price',
          id: 'registerPreOrderPrice',
          type: 'number',
          value: editState.registerPreOrderPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerPreOrderPrice: value,
            });
          },
        },
        {
          name: 'Register Order Price',
          id: 'registerOrderPrice',
          type: 'number',
          value: editState.registerOrderPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerOrderPrice: value,
            });
          },
        },
        {
          name: 'Domestic Shipping Price',
          id: 'shippingPrice',
          type: 'number',
          value: editState.shippingPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              shippingPrice: value,
            });
          },
        },
        {
          name: 'International Shipping Price',
          id: 'internationalShippingPrice',
          type: 'number',
          value: editState.internationalShippingPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              internationalShippingPrice: value,
            });
          },
        },
        {
          name: 'Embossing Price',
          id: 'embossPrice',
          type: 'number',
          value: editState.embossPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              embossPrice: value,
            });
          },
        },
        {
          name: 'Yearbook Past Edition Price',
          id: 'yearbookPastEditionsPrice',
          type: 'number',
          value: editState.yearbookPastEditionsPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookPastEditionsPrice: value,
            });
          },
        },
        {
          name: 'Register Past Edition Price',
          id: 'registerPastEditionsPrice',
          type: 'number',
          value: editState.registerPastEditionsPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerPastEditionsPrice: value,
            });
          },
        },
      ],
    },
    {
      name: 'Parent Ad Pricing',
      inputs: [
        {
          name: 'Yearbook Silver Price',
          id: 'yearbookSilverPrice',
          type: 'number',
          value: editState.yearbookSilverPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookSilverPrice: value,
            });
          },
        },
        {
          name: 'Yearbook Platinum Price',
          id: 'yearbookPlatinumPrice',
          type: 'number',
          value: editState.yearbookPlatinumPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookPlatinumPrice: value,
            });
          },
        },
        {
          name: 'Yearbook John Harvard Price',
          id: 'yearbookJohnHarvardPrice',
          type: 'number',
          value: editState.yearbookJohnHarvardPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookJohnHarvardPrice: value,
            });
          },
        },
        {
          name: 'Yearbook Crimson Price',
          id: 'yearbookCrimsonPrice',
          type: 'number',
          value: editState.yearbookCrimsonPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              yearbookCrimsonPrice: value,
            });
          },
        },
        {
          name: 'Register Silver Price',
          id: 'registerSilverPrice',
          type: 'number',
          value: editState.registerSilverPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerSilverPrice: value,
            });
          },
        },
        {
          name: 'Register Platinum Price',
          id: 'registerPlatinumPrice',
          type: 'number',
          value: editState.registerPlatinumPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerPlatinumPrice: value,
            });
          },
        },
        {
          name: 'Register Crimson Price',
          id: 'registerCrimsonPrice',
          type: 'number',
          value: editState.registerCrimsonPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerCrimsonPrice: value,
            });
          },
        },
        {
          name: 'Register John Harvard Price',
          id: 'registerJohnHarvardPrice',
          type: 'number',
          value: editState.registerJohnHarvardPrice,
          setValue: (value: number) => {
            setEditState({
              ...editState,
              registerJohnHarvardPrice: value,
            });
          },
        },
      ],
    },
    {
      name: 'Cloudinary',
      inputs: [
        {
          name: 'Cloudinary Cloud Name',
          id: 'cloudinaryCloudName',
          type: 'text',
          value: editState.cloudinaryCloudName,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              cloudinaryCloudName: value,
            });
          },
        },
        {
          name: 'Cloudinary Upload Preset',
          id: 'cloudinaryUploadPreset',
          type: 'text',
          value: editState.cloudinaryUploadPreset,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              cloudinaryUploadPreset: value,
            });
          },
        },
        {
          name: 'Register Photo Folder',
          id: 'registerPhotoFolder',
          type: 'text',
          value: editState.registerPhotoFolder,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              registerPhotoFolder: value,
            });
          },
        },
        {
          name: 'Register Parent Ad Folder',
          id: 'registerParentAdFolder',
          type: 'text',
          value: editState.registerParentAdFolder,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              registerParentAdFolder: value,
            });
          },
        },
        {
          name: 'Yearbook Parent Ad Folder',
          id: 'yearbookParentAdFolder',
          type: 'text',
          value: editState.yearbookParentAdFolder,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              yearbookParentAdFolder: value,
            });
          },
        },
        {
          name: 'Org Photo Folder',
          id: 'orgPhotoFolder',
          type: 'text',
          value: editState.orgPhotoFolder,
          setValue: (value: string) => {
            setEditState({
              ...editState,
              orgPhotoFolder: value,
            });
          },
        },
      ],
    },
  ];

  const [submittingInfo, setSubmittingInfo] = useState<boolean>(false);

  const submitBioform = () => {
    if (submittingInfo) return;
    setSubmittingInfo(true);

    const validators = {
      currentGraduatingYear: editState.currentGraduatingYear > 0,
      currentIncomingYear: editState.currentIncomingYear > 0,
      summerBegins: !!editState.summerBegins,
      summerEnds: !!editState.summerEnds,
      yearbookPreOrderDeadline: !!editState.yearbookPreOrderDeadline,
      yearbookOrderDeadline: !!editState.yearbookOrderDeadline,
      yearbookOrderLateDeadline: !!editState.yearbookOrderLateDeadline,
      yearbookParentAdDeadline: !!editState.yearbookParentAdDeadline,
      yearbookParentAdLateDeadline: !!editState.yearbookParentAdLateDeadline,
      registerOrderDeadline: !!editState.registerOrderDeadline,
      registerParentAdDeadline: !!editState.registerParentAdDeadline,
      registerParentAdLateDeadline: !!editState.registerParentAdLateDeadline,
      bioformDeadline: !!editState.bioformDeadline,
      bioformLateDeadline: !!editState.bioformLateDeadline,
      alternatePhotoDeadline: !!editState.alternatePhotoDeadline,
      candidsDeadline: !!editState.candidsDeadline,
      orgBioformOpen: !!editState.orgBioformOpen,
      orgBioformDeadline: !!editState.orgBioformDeadline,
      yearbookPreOrderPrice: editState.yearbookPreOrderPrice > 0,
      yearbookOrderPrice: editState.yearbookOrderPrice > 0,
      registerPreOrderPrice: editState.registerPreOrderPrice > 0,
      registerOrderPrice: editState.registerOrderPrice > 0,
      shippingPrice: editState.shippingPrice > 0,
      internationalShippingPrice: editState.internationalShippingPrice > 0,
      embossPrice: editState.embossPrice > 0,
      yearbookPastEditionsPrice: editState.yearbookPastEditionsPrice > 0,
      registerPastEditionsPrice: editState.registerPastEditionsPrice > 0,
      yearbookSilverPrice: editState.yearbookSilverPrice > 0,
      yearbookPlatinumPrice: editState.yearbookPlatinumPrice > 0,
      yearbookJohnHarvardPrice: editState.yearbookJohnHarvardPrice > 0,
      yearbookCrimsonPrice: editState.yearbookCrimsonPrice > 0,
      registerSilverPrice: editState.registerSilverPrice > 0,
      registerPlatinumPrice: editState.registerPlatinumPrice > 0,
      registerCrimsonPrice: editState.registerCrimsonPrice > 0,
      registerJohnHarvardPrice: editState.registerJohnHarvardPrice > 0,
      cloudinaryCloudName: !!editState.cloudinaryCloudName,
      cloudinaryUploadPreset: !!editState.cloudinaryUploadPreset,
      registerPhotoFolder: !!editState.registerPhotoFolder,
      registerParentAdFolder: !!editState.registerParentAdFolder,
      yearbookParentAdFolder: !!editState.yearbookParentAdFolder,
      orgPhotoFolder: !!editState.orgPhotoFolder,
    };

    if (!Object.values(validators).every((v) => v)) {
      // eslint-disable-next-line no-alert
      alert('Please fill out all required fields');
      setSubmittingInfo(false);
      return;
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}/constants`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentGraduatingYear: editState.currentGraduatingYear,
        currentIncomingYear: editState.currentIncomingYear,
        summerBegins: editState.summerBegins.toISOString(),
        summerEnds: editState.summerEnds.toISOString(),
        yearbookPreOrderDeadline:
          editState.yearbookPreOrderDeadline.toISOString(),
        yearbookOrderDeadline: editState.yearbookOrderDeadline.toISOString(),
        yearbookOrderLateDeadline:
          editState.yearbookOrderLateDeadline.toISOString(),
        yearbookParentAdDeadline:
          editState.yearbookParentAdDeadline.toISOString(),
        yearbookParentAdLateDeadline:
          editState.yearbookParentAdLateDeadline.toISOString(),
        registerOrderDeadline: editState.registerOrderDeadline.toISOString(),
        registerParentAdDeadline:
          editState.registerParentAdDeadline.toISOString(),
        registerParentAdLateDeadline:
          editState.registerParentAdLateDeadline.toISOString(),
        bioformDeadline: editState.bioformDeadline.toISOString(),
        bioformLateDeadline: editState.bioformLateDeadline.toISOString(),
        alternatePhotoDeadline: editState.alternatePhotoDeadline.toISOString(),
        candidsDeadline: editState.candidsDeadline.toISOString(),
        orgBioformOpen: editState.orgBioformOpen.toISOString(),
        orgBioformDeadline: editState.orgBioformDeadline.toISOString(),
        yearbookPreOrderPrice: editState.yearbookPreOrderPrice,
        yearbookOrderPrice: editState.yearbookOrderPrice,
        registerPreOrderPrice: editState.registerPreOrderPrice,
        registerOrderPrice: editState.registerOrderPrice,
        shippingPrice: editState.shippingPrice,
        internationalShippingPrice: editState.internationalShippingPrice,
        embossPrice: editState.embossPrice,
        yearbookPastEditionsPrice: editState.yearbookPastEditionsPrice,
        registerPastEditionsPrice: editState.registerPastEditionsPrice,
        yearbookSilverPrice: editState.yearbookSilverPrice,
        yearbookPlatinumPrice: editState.yearbookPlatinumPrice,
        yearbookJohnHarvardPrice: editState.yearbookJohnHarvardPrice,
        yearbookCrimsonPrice: editState.yearbookCrimsonPrice,
        registerSilverPrice: editState.registerSilverPrice,
        registerPlatinumPrice: editState.registerPlatinumPrice,
        registerCrimsonPrice: editState.registerCrimsonPrice,
        registerJohnHarvardPrice: editState.registerJohnHarvardPrice,
        cloudinaryCloudName: editState.cloudinaryCloudName,
        cloudinaryUploadPreset: editState.cloudinaryUploadPreset,
        registerPhotoFolder: editState.registerPhotoFolder,
        registerParentAdFolder: editState.registerParentAdFolder,
        yearbookParentAdFolder: editState.yearbookParentAdFolder,
        orgPhotoFolder: editState.orgPhotoFolder,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          fetch(`${process.env.REACT_APP_BACKEND_URL}/constants`, {
            method: 'GET',
            credentials: 'include',
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.status === 'success') {
                if (res.data.summerBegins)
                  res.data.summerBegins = dayjs(
                    new Date(res.data.summerBegins),
                  );
                if (res.data.summerEnds)
                  res.data.summerEnds = dayjs(new Date(res.data.summerEnds));
                if (res.data.yearbookPreOrderDeadline)
                  res.data.yearbookPreOrderDeadline = dayjs(
                    new Date(res.data.yearbookPreOrderDeadline),
                  );
                if (res.data.yearbookOrderDeadline)
                  res.data.yearbookOrderDeadline = dayjs(
                    new Date(res.data.yearbookOrderDeadline),
                  );
                if (res.data.yearbookOrderLateDeadline)
                  res.data.yearbookOrderLateDeadline = dayjs(
                    new Date(res.data.yearbookOrderLateDeadline),
                  );
                if (res.data.yearbookParentAdDeadline)
                  res.data.yearbookParentAdDeadline = dayjs(
                    new Date(res.data.yearbookParentAdDeadline),
                  );
                if (res.data.yearbookParentAdLateDeadline)
                  res.data.yearbookParentAdLateDeadline = dayjs(
                    new Date(res.data.yearbookParentAdLateDeadline),
                  );
                if (res.data.registerOrderDeadline)
                  res.data.registerOrderDeadline = dayjs(
                    new Date(res.data.registerOrderDeadline),
                  );
                if (res.data.registerParentAdDeadline)
                  res.data.registerParentAdDeadline = dayjs(
                    new Date(res.data.registerParentAdDeadline),
                  );
                if (res.data.registerParentAdLateDeadline)
                  res.data.registerParentAdLateDeadline = dayjs(
                    new Date(res.data.registerParentAdLateDeadline),
                  );
                if (res.data.bioformDeadline)
                  res.data.bioformDeadline = dayjs(
                    new Date(res.data.bioformDeadline),
                  );
                if (res.data.bioformLateDeadline)
                  res.data.bioformLateDeadline = dayjs(
                    new Date(res.data.bioformLateDeadline),
                  );
                if (res.data.alternatePhotoDeadline)
                  res.data.alternatePhotoDeadline = dayjs(
                    new Date(res.data.alternatePhotoDeadline),
                  );
                if (res.data.candidsDeadline)
                  res.data.candidsDeadline = dayjs(
                    new Date(res.data.candidsDeadline),
                  );
                if (res.data.orgBioformOpen)
                  res.data.orgBioformOpen = dayjs(
                    new Date(res.data.orgBioformOpen),
                  );
                if (res.data.orgBioformDeadline)
                  res.data.orgBioformDeadline = dayjs(
                    new Date(res.data.orgBioformDeadline),
                  );

                dispatch(setConstants(res.data));
              }
            });

          // eslint-disable-next-line no-alert
          alert('Constants submitted successfully');
        } else {
          // eslint-disable-next-line no-alert
          alert('There was an error submitting the constants');
        }
        setSubmittingInfo(false);
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(
          'There was an error submitting the constants. Please try again later.',
        );
        setSubmittingInfo(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Constants</Typography>
      </Box>

      <Box
        sx={{
          pl: '10%',
          width: '80%',
          pt: 4,
          display: 'grid',
        }}
      >
        <Grid container spacing={4}>
          {categories.map((category) => (
            <Grid container item xs={12} spacing={2}>
              <Typography variant="h4">{category.name}</Typography>
              {category.inputs.map((input) => (
                <Grid item xs={12}>
                  {input.type === 'number' && (
                    <TextField
                      required
                      fullWidth
                      id={camelize(input.name)}
                      label={input.name}
                      variant="outlined"
                      value={input.value}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (
                          Number.isNaN(parseInt(e.target.value, 10)) ||
                          value < 0
                        ) {
                          input.setValue(0);
                        } else {
                          input.setValue(parseInt(e.target.value, 10));
                        }
                      }}
                    />
                  )}

                  {input.type === 'date' && (
                    <DatePicker
                      label={input.name}
                      value={input.value}
                      onChange={(newValue) => input.setValue(dayjs(newValue))}
                    />
                  )}

                  {input.type === 'text' && (
                    <TextField
                      required
                      fullWidth
                      id={camelize(input.name)}
                      label={input.name}
                      variant="outlined"
                      value={input.value}
                      onChange={(e) => input.setValue(e.target.value)}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button fullWidth variant="contained" onClick={submitBioform}>
              {submittingInfo ? <CircularProgress /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ConstantsPage;
